import { Appointment, PatientAttentionStatus } from "@/models";
import { TPatientAttention } from "@/models/patientAttention";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CURRENT_STATUS: TPatientAttention = {
    appointmentSelected: {
        admittedDate: "0000-00-00 00:00:00",
        age: 0,
        alwaysDilate: 0,
        app_id: 0,
        companyName: "",
        companyType: "",
        consultingRoom: "",
        contractName: "",
        cupCode: "",
        cluBirthDate: "",
        firstDilationTime: "",
        firstTime: 0,
        idService: 0,
        isDilated: 0,

        medFirstName: "",
        medLastName: "",
        medSecondName: "",
        medSecondSurname: "",
        modalityAppoinment: "on_site",
        patientId: 0,
        patientIdentification: "",
        patientName: "",
        reqDilate: 0,
        serviceCUP: "",
        serviceName: "",
        serviceType: "",
        site: "",
        sigeVerified: 0,
        startAppointment: "",
        status: "cancelled",
        statusBgColor: "#D6E0EB",
        statusFontColor: "#00B4CC",
        statusName: "Fallida",
    },
    patientStatus: {
        clhId: 0,
        mcfId: 0,
        cluId: 0,
        sheet: "",
        status: "",
        referringDoctor: "",
        reportRequired: false,
        requiresDilation: false,
        requiresImage: false,
        vars: [],
    },
};

const initialState: TPatientAttention = {
    appointmentSelected: JSON.parse(localStorage.getItem("appointment") as string)
        ? JSON.parse(localStorage.getItem("appointment") as string)
        : CURRENT_STATUS.appointmentSelected,
    patientStatus: JSON.parse(localStorage.getItem("patientStatus") as string)
        ? JSON.parse(localStorage.getItem("patientStatus") as string)
        : CURRENT_STATUS.patientStatus,
};

export const patientAttentionSlice = createSlice({
    name: "patientAttention",
    initialState,
    reducers: {
        setAppointmentSelected: (state, { payload }: PayloadAction<Appointment>) => {
            state.appointmentSelected = payload;
        },
        setPatientStatus: (state, action: PayloadAction<PatientAttentionStatus>) => {
            state.patientStatus = action.payload;
        },
    },
});

export const { setAppointmentSelected, setPatientStatus } = patientAttentionSlice.actions;
