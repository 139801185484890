import { FormEvent, useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "@/components";
import { getSheetSegments } from "./generalHistory.actions";
import { SheetSegment } from "@/models/generalHistory/generalHistory";

interface IConfigSegments {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: Record<string, boolean>) => void;
    config: Record<string, boolean> | null;
}

export default function ConfigSegments(props: IConfigSegments) {
    const [sheetSegments, setSheetSegments] = useState<SheetSegment[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getSheetSegments();
            setSheetSegments(response);
        }
        fetchData();
    }, []);

    const onSubmitSegments = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const target = event.target as HTMLFormElement;
        const result: Record<string, boolean> = {};

        for (const segmentElement of target.elements) {
            const segment = segmentElement as HTMLInputElement;
            result[segment.name] = segment.checked;
        }

        props.onSubmit(result);
    };

    const onClose = () => {
        props.onClose();
    };

    const renderComponent = () => {
        return (
            <Modal isOpen={props.isOpen} onClose={onClose} positionModal="center" width={400}>
                <div className="p-3">
                    <h3 className="text-primary fw-bold text-center">Configurar segmentos</h3>
                    <hr className="mb-4" />
                    <form onSubmit={onSubmitSegments}>
                        {sheetSegments.map(segment => (
                            <div className="border-bottom d-flex p-3" key={segment.id}>
                                <Checkbox name={segment.tag} defaultChecked={props.config?.[segment.tag] ?? true} label={segment.name} />
                            </div>
                        ))}
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="button" variant="outline" className="me-3" onClick={onClose}>Cancelar</Button>
                            <Button type="submit" variant="primary">Aceptar</Button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    };

    return renderComponent();
}