import { useRef } from "react";
import { DebouncedState } from "use-debounce";
//
import { SectionCard } from "@/components";
import { OrthopticPayload } from "@/models/sheets/orthoptic";

interface ChromaticVisionParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: DebouncedState<() => void>;
    isDisabledForm: boolean;
    handleOpenNoInsTextModal: ({ current, finishSelection } : { current: string, finishSelection?: (value: string) => void }) => void
}

export default function ChromaticVision({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, handleOpenNoInsTextModal }: ChromaticVisionParams) {
    const diskRefOD = useRef<HTMLInputElement>(null);
    const diskRefOI = useRef<HTMLInputElement>(null);
    const correctRefOD = useRef<HTMLInputElement>(null);
    const correctRefOI = useRef<HTMLInputElement>(null);

    const onChangeIshihara = (label: "disk" | "correct" | "observations", value: string, eye: "od" | "oi" | null) => {
        let options;

        if (eye !== null) {
            options = {
                ...orthopticPayload,
                chromaticVision: {
                    ...orthopticPayload.chromaticVision,
                    ishihara: {
                        ...orthopticPayload.chromaticVision?.ishihara,
                        [eye]: {
                            ...orthopticPayload.chromaticVision?.ishihara?.[eye],
                            [label]: value === "" ? null : value,
                        },
                    },
                },
            };
        } else {
            options = {
                ...orthopticPayload,
                chromaticVision: {
                    ...orthopticPayload.chromaticVision,
                    ishihara: {
                        ...orthopticPayload.chromaticVision?.ishihara,
                        [label]: value === "" ? null : value,
                    },
                },
            };
        }

        setOrthopticPayload(options);
        if (Number(options.chromaticVision.ishihara.od?.disk) >= Number(options.chromaticVision.ishihara.od?.correct)) {
            diskRefOD.current?.classList.remove("border-danger");
            correctRefOD.current?.classList.remove("border-danger");
            if (eye === "od") {
                onSubmit();
            }
        } else {
            diskRefOD.current?.classList.add("border-danger");
            onSubmit.cancel();
        }
        if (Number(options.chromaticVision.ishihara.oi?.disk) >= Number(options.chromaticVision.ishihara.oi?.correct)) {
            diskRefOI.current?.classList.remove("border-danger");
            correctRefOI.current?.classList.remove("border-danger");
            if (eye === "oi") {
                onSubmit();
            }
        } else {
            diskRefOI.current?.classList.add("border-danger");
            onSubmit.cancel();
        }
    };

    const onChangeFarnsworthTest = (eye: "od" | "oi", value: string) => {
        const options = {
            ...orthopticPayload,
            chromaticVision: {
                ...orthopticPayload.chromaticVision,
                farnsworthTest: {
                    ...orthopticPayload.chromaticVision?.farnsworthTest,
                    [eye]: value,
                },
            },
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const formatScore = (value?: number): string => {
        if (typeof value !== "number" || isNaN(value)) {
            return "";
        }
        return `${(value * 100).toFixed(2)}%`;
    };

    const render = () => {
        const diskOD = Number(orthopticPayload.chromaticVision?.ishihara?.od?.disk);
        const diskOI = Number(orthopticPayload.chromaticVision?.ishihara?.oi?.disk);
        const correctOD = Number(orthopticPayload.chromaticVision?.ishihara?.od?.correct);
        const correctOI = Number(orthopticPayload.chromaticVision?.ishihara?.oi?.correct);

        let scoreOD: string | undefined = undefined;
        if (!isNaN(diskOD)  && !isNaN(correctOD) && diskOD >= correctOD) {
            scoreOD = formatScore((correctOD / diskOD));
        } else {
            scoreOD = undefined;
        }
        let scoreOI: string | undefined = undefined;
        if (!isNaN(diskOI) &&!isNaN(correctOI) && diskOI >= correctOI) {
            scoreOI = formatScore((correctOI / diskOI));
        } else {
            scoreOI = undefined;
        }

        return (
            <SectionCard>
                <h5 className="text-label fw-bold">Visión cromática</h5>
                <hr className="text-primary mt-2" />
                <div className=" gy-3 gx-5">
                    <div className="">
                        <h5 className="text-label fw-bold fw-bold">Ishihara</h5>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center justify-content-center me-3 flex-fill">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <td className="p-2"></td>
                                            <td
                                                align="center"
                                                className="text-secondary"
                                            >
                                                Platos
                                            </td>
                                            <td
                                                align="center"
                                                className="text-secondary"
                                            >
                                                Correcto
                                            </td>
                                            <td
                                                align="center"
                                                className="text-secondary"
                                            >
                                                Puntaje
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                className="pt-2 pe-2 ps-2 text-secondary bg-od"
                                                style={{ borderTopLeftRadius: "0.5rem" }}
                                                width={30}
                                            >
                                                OD
                                            </td>
                                            <td
                                                className="py-2 pe-2 ps-2 bg-od"
                                                width={130}
                                            >
                                                <input
                                                    disabled={isDisabledForm}
                                                    ref={diskRefOD}
                                                    type="number"
                                                    className="form-control"
                                                    value={orthopticPayload.chromaticVision?.ishihara?.od?.disk}
                                                    onChange={({ target }) => onChangeIshihara("disk", target.value, "od")}
                                                    onKeyDown={(event) =>
                                                        (event.key === "-" || event.key === ".") && event.preventDefault()
                                                    }
                                                />
                                            </td>
                                            <td
                                                className="py-2 pe-2 ps-2 bg-od"
                                                width={130}
                                            >
                                                <input
                                                    disabled={isDisabledForm}
                                                    ref={correctRefOD}
                                                    type="number"
                                                    className="form-control"
                                                    value={orthopticPayload.chromaticVision?.ishihara?.od?.correct}
                                                    onChange={({ target }) => onChangeIshihara("correct", target.value, "od")}
                                                    onKeyDown={(event) =>
                                                        (event.key === "-" || event.key === ".") && event.preventDefault()
                                                    }
                                                />
                                            </td>
                                            <td
                                                className="py-2 pe-2 ps-2 bg-od"
                                                width={130}
                                            >
                                                <input
                                                    disabled={isDisabledForm}
                                                    type="text"
                                                    className="form-control"
                                                    value={scoreOD}

                                                    readOnly
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                className="py-2 pe-2 ps-2 text-secondary bg-oi"
                                                style={{ borderBottomLeftRadius: "0.5rem" }}
                                            >
                                                OI
                                            </td>
                                            <td className="py-2 pe-2 ps-2 bg-oi">
                                                <input
                                                    disabled={isDisabledForm}
                                                    ref={diskRefOI}
                                                    type="number"
                                                    className="form-control"
                                                    value={orthopticPayload.chromaticVision?.ishihara?.oi?.disk}
                                                    onChange={({ target }) => onChangeIshihara("disk", target.value, "oi")}
                                                    onKeyDown={(event) =>
                                                        (event.key === "-" || event.key === ".") && event.preventDefault()
                                                    }
                                                />
                                            </td>
                                            <td className="py-2 pe-2 ps-2 bg-oi">
                                                <input
                                                    disabled={isDisabledForm}
                                                    ref={correctRefOI}
                                                    type="number"
                                                    className="form-control"
                                                    value={orthopticPayload.chromaticVision?.ishihara?.oi?.correct}
                                                    onChange={({ target }) => onChangeIshihara("correct", target.value, "oi")}
                                                    onKeyDown={(event) =>
                                                        (event.key === "-" || event.key === ".") && event.preventDefault()
                                                    }
                                                />
                                            </td>
                                            <td className="py-2 pe-2 ps-2 bg-oi">
                                                <input
                                                    disabled={isDisabledForm}
                                                    type="text"
                                                    className="form-control"
                                                    value={scoreOI}
                                                    readOnly
                                                />
                                            </td>
                                        </tr>
                                    </tbody>{" "}
                                </table>
                            </div>
                        </div>
                        <input
                            type="text"
                            disabled={isDisabledForm}
                            className="form-control mt-3"
                            placeholder="Observaciones..."
                            value={orthopticPayload.chromaticVision?.ishihara?.observations}
                            onChange={({ target }) => onChangeIshihara("observations", target.value, null)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal({ current: "ishihara_obs", finishSelection: (value)=>{
                                    onChangeIshihara("observations", orthopticPayload.chromaticVision?.ishihara?.observations ?
                                        `${orthopticPayload.chromaticVision?.ishihara?.observations} ${value}` : value, null);
                                }});
                            }}
                        />
                    </div>
                    <div className="pt-2">
                        <h5 className="text-label fw-bold">Farnsworth test</h5>
                        <div
                            className="d-flex align-items-center justify-content-center flex-fill mt-3 p-2 bg-od"
                            style={{ borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
                        >
                            <span
                                className="text-secondary"
                                style={{ width: 30 }}
                            >
                                OD
                            </span>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={orthopticPayload.chromaticVision?.farnsworthTest?.od}
                                onChange={({ target }) => onChangeFarnsworthTest("od", target.value)}
                            />
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-center flex-fill bg-oi p-2 "
                            style={{ borderRadius: "0rem 0rem 0.5rem 0.5rem" }}
                        >
                            <span
                                className="text-secondary"
                                style={{ width: 30 }}
                            >
                                OI
                            </span>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={orthopticPayload.chromaticVision?.farnsworthTest?.oi}
                                onChange={({ target }) => onChangeFarnsworthTest("oi", target.value)}
                            />
                        </div>
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
