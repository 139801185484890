import { IconChartHistogram, IconChevronLeft, IconCircleCheck, IconClipboardHeart, IconExclamationMark, IconX } from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import { Avatar, Button, Modal } from "@/components";
//
// import { Records } from "./Tabs/Records";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fireWarningAlert, formatteDocument } from "@/utils";
import { createComment, deleteComment, getComments } from "../Comments/comments.actions";
import { getPatientHeader } from "./preConsultation.actions";
//
import { DilatationRes, IVitalSigns, TData } from "@/models";
import { Comment } from "@/models/comment";
import { Segments } from "@/models/generalFields";
import { ReconciliationMedicine } from "@/models/pre-consultation/medicine";
import { PatientHeader } from "@/models/preconsultation";
import { DIListItem } from "@/models/select/diagnosticImpression";
import { OrthopticPayload } from "@/models/sheets/orthoptic";
import { Tooltip } from "react-tooltip";
import Comments from "../Comments/Comments";
import ConsultationControls from "../Controls/Controls";
import Antecedents from "../Header/Antecedents/AntedecentsModal";
import History from "../History/History";
import Orthoptic from "../Orthoptic/Orthoptic";
import "./PreConsultation.scss";
import { Dilatation, Medicine, Screening, VitalSigns } from "./Tabs";

export default function PreConsultation({
    historySheet,
    data,
    appId,
}: {
    historySheet?: boolean;
    data?: {
        dataSheet: {
            dilatation: DilatationRes;
            medicationReconciliation: { currentUse: ReconciliationMedicine[]; suspended: ReconciliationMedicine[] };
            vitalSings: IVitalSigns;
            ocularSifting: TData;
            orthoptic: OrthopticPayload;
        };
        generalsFields: Segments[];
        diagnosticImpression: DIListItem[];
    };
    appId?: number;
}) {
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { dilatation, medicationReconciliation, ocularSifting, vitalSings, orthoptic } = data?.dataSheet || {};

    const appointment = useAppSelector((state) => state.patientAttention.appointmentSelected);
    const workspace = useAppSelector((state) => state.workspace);
    const isDisabledForm = historySheet ? historySheet : false;
    dayjs.extend(relativeTime);

    const [isAnalysisOpen, setIsAnalysisOpen] = useState<boolean>(false);
    const [patientHeader, setPatientHeader] = useState<PatientHeader>();
    const [currentTab, setCurrentTab] = useState<string>((state?.tab as string) || "dilatation");
    const tabs: { tab: string; label: string }[] = [
        { tab: "dilatation", label: "Dilatación" },
        { tab: "vitalSigns", label: "Signos Vitales" },
        { tab: "eyescreening", label: "Tamizaje" },
        { tab: "medicines", label: "Conciliación medicamentosa" },
        { tab: "orthoptic", label: "Ortóptica" },
    ];
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [comments, setComments] = useState<Comment[]>([]);
    const [antecedentsConfig, setAntecedentsConfig] = useState({
        isOpen: false,
    });

    useEffect(() => {
        async function fetchData() {
            if (appointment?.patientId && !historySheet) {
                const data = await dispatch(
                    getComments({
                        entity: "userclient",
                        id: appointment.patientId,
                    })
                );
                setComments(data as Comment[]);
            }
        }
        fetchData();
    }, [dispatch, appointment?.patientId]);
    useEffect(() => {
        async function fetchData() {
            if (appointment.app_id && workspace.id && !historySheet) {
                const data = await dispatch(
                    getPatientHeader({
                        appId: appointment.app_id,
                        eaccount: workspace.id,
                    })
                );
                setPatientHeader(data);
            }
        }
        fetchData();
    }, [appointment.app_id, workspace.id, dispatch]);

    const handleCloseCommentsDrawer = (): void => setIsCommentsDrawerOpen(false);
    const handleOpenCommentsDrawer = (): void => setIsCommentsDrawerOpen(true);

    const handleCloseAntecedentsModal = (): void =>
        setAntecedentsConfig({
            isOpen: false,
        });
    const handleOpenAntecedentsModal = (): void =>
        setAntecedentsConfig({
            isOpen: true,
        });

    const handleGoBack = (): void => navigate(-1);

    const handleChangeTab = (tab: string): void => {
        setCurrentTab(tab);
    };

    const handleAnalysis = () => {
        if (patientHeader?.analysisPlanJst) {
            setIsAnalysisOpen(true);
        } else {
            fireWarningAlert("El médico no ha ingresado la información.", "Análisis y plan");
        }
    };

    const handleReloadHeader = async () => {
        if (appointment.app_id && workspace.id) {
            const data = await dispatch(
                getPatientHeader({
                    appId: appointment.app_id,
                    eaccount: workspace.id,
                })
            );
            setPatientHeader(data);
        }
    };

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(
            createComment({
                comment: text,
                entity: "userclient",
                userId: userId as number,
                id: appointment?.patientId as number,
            })
        );
        const data = await dispatch(
            getComments({
                entity: "userclient",
                id: appointment?.patientId,
            })
        );
        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(
            getComments({
                entity: "userclient",
                id: appointment?.patientId,
            })
        );
        setComments(data as Comment[]);
    };

    const renderAnalysisModal = () => {
        return (
            <Modal
                width={600}
                isOpen={isAnalysisOpen}
                onClose={() => setIsAnalysisOpen(false)}
            >
                <h2 className="text-primary fw-bold align-self-center">Análisis y plan</h2>
                <hr className="text-muted" />
                {patientHeader?.analysisPlanJst}
                <Button
                    onClick={() => setIsAnalysisOpen(false)}
                    variant="primary"
                    className="align-self-end mt-3"
                >
                    Aceptar
                </Button>
            </Modal>
        );
    };

    const renderHeader = () => {
        return (
            <div className="d-flex mt-4 px-4">
                <div className="d-flex align-items-center flex-row w-100 card px-3 py-1">
                    {!(workspace.profile?.prefijo.toLowerCase() === "nurse" && appointment.status === "attended") && (
                        <div
                            className="align-self-center text-secondary pointer"
                            onClick={handleGoBack}
                        >
                            <IconChevronLeft />
                        </div>
                    )}
                    <div className="d-flex align-items-center ms-3">
                        <Avatar
                            src={
                                appointment.photoUrl !== null && appointment.photoUrl !== ""
                                    ? appointment.photoUrl
                                    : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            }
                        />
                        <div className="ms-3">
                            <small className="text-muted">Paciente</small>
                            <div className="fw-bold text-secondary">
                                {patientHeader?.cluFirstName} {patientHeader?.cluSecondName} {patientHeader?.cluLastName}
                            </div>
                        </div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Identificación</small>
                        <div className="fw-bold text-secondary">
                            {patientHeader?.docTypeDescription} {formatteDocument({ value: patientHeader?.cluDocument }).format}
                        </div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Edad</small>
                        <div className="fw-bold text-secondary">{dayjs(patientHeader?.cluBirthDate).fromNow(true)}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Aseguradora</small>
                        <div className="fw-bold text-secondary">{patientHeader?.cclientName}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Contrato</small>
                        <div className="fw-bold text-secondary">{patientHeader?.contractName}</div>
                    </div>
                    <div className="flex-fill"></div>
                    <div
                        className="pointer mx-2 text-primary align-self-center"
                        onClick={handleOpenAntecedentsModal}
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={"Antecedentes"}
                    >
                        <IconClipboardHeart />
                    </div>
                    <div className="d-flex align-items-center">
                        {patientHeader?.alertsOcularNotes && (
                            <div
                                className="dynamic-icon dynamic-icon__warning ms-3"
                                data-tooltip-content={patientHeader.alertsOcularNotes}
                                data-tooltip-id="allergies-tooltip"
                            >
                                <IconX />
                            </div>
                        )}
                        {patientHeader?.allergiesGeneralNotes && (
                            <div
                                className="dynamic-icon dynamic-icon__danger ms-3"
                                data-tooltip-content={patientHeader.allergiesGeneralNotes}
                                data-tooltip-id="allergies-tooltip"
                            >
                                <IconExclamationMark />
                            </div>
                        )}
                        <div
                            className="text-primary pointer ms-3"
                            onClick={handleAnalysis}
                        >
                            <IconChartHistogram size={20} />
                        </div>
                        <div
                            className="message-icon ms-3"
                            onClick={handleOpenCommentsDrawer}
                        >
                            {comments?.length}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="preconsultation">
                <div className="preconsultation-main">
                    <div className="d-flex flex-column overflow-hidden flex-grow-1">
                        {!historySheet && renderHeader()}
                        <div className="preconsultation-tabs mt-3 px-4">
                            {tabs.filter(tab => state?.sheet === "orthoptic" ? tab.tab !== "orthoptic" : tab).map((tab) => (
                                <div
                                    key={tab.tab}
                                    className={`preconsultation-tab ${currentTab === tab.tab && "active"}`}
                                    onClick={() => handleChangeTab(tab.tab)}
                                >
                                    {!historySheet && <IconCircleCheck />}
                                    <span>{tab.label}</span>
                                </div>
                            ))}
                        </div>
                        <div className="flex-grow-1 overflow-auto pb-4 px-4">
                            {currentTab === "dilatation" && (
                                <Dilatation
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={dilatation}
                                />
                            )}
                            {/* {currentTab === "records" && <Records />} */}
                            {currentTab === "vitalSigns" && (
                                <VitalSigns
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={vitalSings}
                                />
                            )}
                            {currentTab === "eyescreening" && (
                                <Screening
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={ocularSifting}
                                />
                            )}
                            {currentTab === "medicines" && (
                                <Medicine
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={medicationReconciliation}
                                />
                            )}
                            {currentTab === "orthoptic" && (
                                <Orthoptic
                                    showRequiredFields={false}
                                    historySheet={historySheet}
                                    data={{ dataSheet: orthoptic, generalsFields: [], diagnosticImpression: [] }}
                                />
                            )}
                        </div>
                    </div>
                    {workspace.profile?.prefijo.toLowerCase() === "nurse" && !historySheet && (
                        <ConsultationControls isDisabledForm={isDisabledForm} />
                    )}
                </div>
                {!historySheet && (
                    <History
                        showClose={false}
                        completePathHistorySegments={"preconsulting"}
                    />
                )}
                {!historySheet && (
                    <Antecedents
                        isOpen={antecedentsConfig.isOpen}
                        onClose={handleCloseAntecedentsModal}
                        onSuccess={handleReloadHeader}
                        isDisabledForm={isDisabledForm}
                    />
                )}
                {!historySheet && (
                    <Comments
                        data={comments}
                        isOpen={isCommentsDrawerOpen}
                        onClose={handleCloseCommentsDrawer}
                        width={400}
                        drawer="right"
                        onCreateComment={(value) => handleSubmitComment(value)}
                        onDeleteComment={(id) => handleDeleteComment(id)}
                    />
                )}
                {renderAnalysisModal()}
                <Tooltip id="allergies-tooltip" />
            </div>
        );
    };

    return render();
}
