import { CommonSelect, ODSections, OISections, OrthopticPayload, SelectData } from "@/models/sheets/orthoptic";
import { useState } from "react";
import SelectModal from "../SelectModal/SelectModal";

interface VersionProps {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    readOnly?: boolean;
    versions: CommonSelect[];
    isDetail?: boolean;
    showHistory?: boolean;
    isDisabledForm?: boolean;
    dataVersionList?: CommonSelect[];
    handleOpenNoInsTextModal?: ({ current, finishSelection }:{ current: string, finishSelection?: (value:string) => void }) => void

}

export default function Version({ dataVersionList ,orthopticPayload, setOrthopticPayload, onSubmit, readOnly, versions, isDetail, showHistory, isDisabledForm, handleOpenNoInsTextModal }: VersionProps) {
    const [selectModalConfig, setSelectModalConfig] = useState<{ isOpen: boolean; data?: SelectData; }>({
        isOpen: false,
    });

    const onCloseModal = () => {
        setSelectModalConfig({
            isOpen: false,
            data: undefined
        });
    };

    const onOpenModal = (option: SelectData) => {
        setSelectModalConfig({
            isOpen: true,
            data: option
        });
    };

    const onChangeObservations = (eye: "od" | "oi", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            versions: {
                ...orthopticPayload.versions,
                [eye]: {
                    ...orthopticPayload.versions?.[eye],
                    observations: value
                }
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onSubmitVersionOD = (segment: ODSections, value: number) => {
        const option: OrthopticPayload = {
            ...orthopticPayload,
            versions: {
                ...orthopticPayload.versions,
                od: {
                    ...orthopticPayload.versions?.od,
                    [segment]: value
                }
            }
        };
        setOrthopticPayload(option);
        onSubmit();
    };

    const onSubmitVersionOI = (segment: OISections, value: number) => {
        const option: OrthopticPayload = {
            ...orthopticPayload,
            versions: {
                ...orthopticPayload.versions,
                oi: {
                    ...orthopticPayload.versions?.oi,
                    [segment]: value
                }
            }
        };
        setOrthopticPayload(option);
        onSubmit();
    };

    const onCleanVersion = (eye: "od" | "oi", segment: string) => {
        setOrthopticPayload({
            ...orthopticPayload,
            versions: {
                ...orthopticPayload.versions,
                [eye]: {
                    ...orthopticPayload.versions?.[eye],
                    [segment]: null
                }
            }
        });
    };

    const renderOIVersion = () => {
        const oii = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.oii);
        const rs = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.rs);
        const rsl = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.rsl);
        const rmi = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.rmi);
        const rli = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.rli);
        const osi = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.osi);
        const ri = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.ri);
        const rii = versions.find(ver => ver.id === orthopticPayload.versions?.oi?.rii);

        return (
            <div className="border rounded py-3 bg-white">
                <div className={`${showHistory && "history-open"} version-img`}>
                    <div></div>
                    <div></div>
                    <table className="version-table">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <span
                                        className={`pointer nowrap ${oii ? "fw-bold" : ""}`}
                                        style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "oii", value: oii?.id })}
                                    >
                                        {oii?.description} OII
                                    </span>
                                </td>
                                <td></td>
                                <td align="center">
                                    <span
                                        className={`pointer nowrap ${oii ? "fw-bold" : ""}`}
                                        style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "rs", value: rs?.id })}
                                    >
                                        {rs?.description} RS
                                    </span>
                                </td>
                                <td></td>
                                <td align="right">
                                    <span
                                        className={`pointer nowrap ${rsl ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "rsl", value: rsl?.id })}
                                    >
                                        {rsl?.description} RSL
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td align="center">
                                    <span className={`pointer nowrap ${rmi ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "rmi", value: rmi?.id })}>
                                        {rmi?.description} RMI
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="center" style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "rli", value: rli?.id })}>
                                    <span className={`pointer nowrap ${rli ? "fw-bold" : ""}`}>
                                        {rli?.description} RLI
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className={`pointer nowrap ${osi ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "osi", value: osi?.id })}>
                                        {osi?.description} OSI
                                    </span>
                                </td>
                                <td></td>
                                <td align="center">
                                    <span className={`pointer nowrap ${rii ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "ri", value: ri?.id })}>
                                        {ri?.description} RI
                                    </span>
                                </td>
                                <td></td>
                                <td align="right">
                                    <span className={`pointer nowrap ${rii ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "oi", section: "rii", value: rii?.id })}>
                                        {rii?.description} RII
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const renderODVersion = () => {
        const rsd = versions.find(ver => ver.id === orthopticPayload.versions?.od?.rsd);
        const rs = versions.find(ver => ver.id === orthopticPayload.versions?.od?.rs);
        const oid = versions.find(ver => ver.id === orthopticPayload.versions?.od?.oid);
        const rld = versions.find(ver => ver.id === orthopticPayload.versions?.od?.rld);
        const rmd = versions.find(ver => ver.id === orthopticPayload.versions?.od?.rmd);
        const rid = versions.find(ver => ver.id === orthopticPayload.versions?.od?.rid);
        const ri = versions.find(ver => ver.id === orthopticPayload.versions?.od?.ri);
        const osd = versions.find(ver => ver.id === orthopticPayload.versions?.od?.osd);

        return (
            <div className="border rounded py-3 bg-white">
                <div className={`${showHistory && "history-open"} version-img`}>
                    <div></div>
                    <div></div>
                    <table className="version-table">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <span className={`pointer nowrap ${rsd ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "rsd", value: rsd?.id })}>
                                        {rsd?.description} RSD
                                    </span>
                                </td>
                                <td></td>
                                <td align="center">
                                    <span className={`pointer nowrap ${rsd ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "rs", value: rs?.id })}>
                                        {rs?.description} RS
                                    </span>
                                </td>
                                <td></td>
                                <td align="right">
                                    <span className={`pointer nowrap ${oid ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "oid", value: oid?.id })}>
                                        {oid?.description} OID
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td align="center">
                                    <span className={`pointer nowrap ${rld ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "rld", value: rld?.id })}>
                                        {rld?.description} RLD
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="center">
                                    <span className={`pointer nowrap ${rmd ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "rmd", value: rmd?.id })}>
                                        {rmd?.description} RMD
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className={`pointer nowrap ${rid ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "rid", value: rid?.id })}>
                                        {rid?.description} RID
                                    </span>
                                </td>
                                <td></td>
                                <td align="center">
                                    <span className={`pointer nowrap ${rid ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "ri", value: ri?.id })}>
                                        {ri?.description} RI
                                    </span>
                                </td>
                                <td></td>
                                <td align="right">
                                    <span className={`pointer nowrap ${rid ? "fw-bold" : ""}`} style={{ pointerEvents: readOnly ? "none" : undefined }} onClick={() => !isDisabledForm && onOpenModal({ eye: "od", section: "osd", value: osd?.id })}>
                                        {osd?.description} OSD
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };


    const render = () => {
        return (
            <div className="row mt-2">
                <div className="col-6" style={{ paddingRight: 0 }}>
                    <div className="bg-od p-2" style={{ borderRadius: "0.5rem 0rem 0rem 0.5rem" }}>
                        <h5 className="fw-bold text-center text-secondary mb-3">OD</h5>
                        {renderODVersion()}
                    </div>
                    <div style={{ paddingRight: 5 }}>
                        {!isDetail ? (
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize mt-3"
                                placeholder="Observaciones..."
                                rows={5}
                                value={orthopticPayload.versions?.od?.observations}
                                onChange={({ target }) => onChangeObservations("od", target.value)}
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    if(handleOpenNoInsTextModal && !isDisabledForm){
                                        handleOpenNoInsTextModal({ current: "versions_od_obs", finishSelection: (value)=>{
                                            onChangeObservations("od", orthopticPayload?.versions?.od?.observations ?
                                                `${orthopticPayload?.versions?.od?.observations}\n${value}` : value);
                                        }});
                                    }

                                }}
                            />
                        ) : ""}
                    </div>
                </div>
                <div className="col-6" style={{ paddingLeft: 0 }}>
                    <div className="bg-oi p-2" style={{ borderRadius: "0rem 0.5rem 0.5rem 0rem" }}>
                        <h5 className="fw-bold text-center text-secondary mb-3">OI</h5>
                        {renderOIVersion()}
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                        {!isDetail ? (
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize mt-3"
                                placeholder="Observaciones..."
                                rows={5}
                                value={orthopticPayload.versions?.oi?.observations}
                                onChange={({ target }) => onChangeObservations("oi", target.value)}
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    if(handleOpenNoInsTextModal && !isDisabledForm){
                                        handleOpenNoInsTextModal({ current: "versions_oi_obs", finishSelection: (value)=>{
                                            onChangeObservations("oi", orthopticPayload.versions?.oi?.observations ?
                                                `${orthopticPayload.versions?.oi?.observations}\n${value}` : value);
                                        }});
                                    }

                                }}
                            />
                        ) : ""}
                    </div>
                </div>
                {isDetail ? (
                    <div className="mt-3">
                        <h5>
                            <span className="fw-bold">Observaciones OD: </span>
                            {orthopticPayload.versions?.od?.observations}
                        </h5>
                        <h5>
                            <span className="fw-bold">Observaciones OI: </span>
                            {orthopticPayload.versions?.oi?.observations}
                        </h5>
                    </div>
                ) : ""}
                <SelectModal
                    data={selectModalConfig.data}
                    isOpen={selectModalConfig.isOpen}
                    onClose={onCloseModal}
                    dataVersionList={dataVersionList}
                    onSelectOd={(data) => onSubmitVersionOD(data.segment, data.value as number)}
                    onSelectOi={(data) => onSubmitVersionOI(data.segment, data.value as number)}
                    onCleanSelect={(eye, segment) => onCleanVersion(eye, segment)}
                />
            </div>
        );
    };

    return render();
}