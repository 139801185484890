import { Segments as IGeneralFields } from "@/models/generalFields";

export default function GeneralFields(props: { generalFields: IGeneralFields; }) {
    const motiveIss = props.generalFields?.predefinedTxts ? props.generalFields?.predefinedTxts?.find(t => t.prefix === "current_disease") : { predifinedText: [] };
    const motiveReason = props.generalFields?.predefinedTxts ? props.generalFields?.predefinedTxts?.find(t => t.prefix === "reason_attention") : { predifinedText: [] };

    const renderComponent = () => {
        return (
            <>
                <div className="item text-muted">
                    {!!props.generalFields?.generalFields?.attentionOrigin && (
                        <>
                            <h4 className="text-primary fw-bold mb-3">
                                Origen de atención
                            </h4>
                            <h5>
                                <span className="fw-bold">Origen de la atención: </span>{" "}
                                {props?.generalFields.generalFields.attentionOrigin?.name}
                            </h5>
                        </>
                    )}

                    {(props.generalFields?.reasonForConsultingJst || (motiveReason?.predifinedText?.length || 0) > 0) && (
                        <>
                            <h5 className="fw-bold mt-3">
                                Motivo consulta:
                            </h5>
                            <h5>{motiveReason?.predifinedText.map(x => x.name).join(", ")}</h5>
                            <h5>{props.generalFields?.reasonForConsultingJst}</h5>
                        </>
                    )}
                    {(props.generalFields?.currentDiseaseJst || (motiveIss?.predifinedText?.length || 0) > 0) && (
                        <>
                            <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                            </h5>
                            <h5>{motiveIss?.predifinedText.map(x => x.name).join(", ")}</h5>
                            <h5>{props.generalFields?.currentDiseaseJst || ""}</h5>
                        </>
                    )}
                    {!!props.generalFields?.generalFields?.abserveEvent && (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {props.generalFields.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    )}

                    {!!props.generalFields?.generalFields?.evolutionTime && (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {props.generalFields.generalFields.evolutionTime} - {props.generalFields.generalFields?.evolutionTimeUnit?.name}
                        </h5>
                    )}

                    {!!props.generalFields?.analysisPlanJst && (
                        <h5>
                            <span className="fw-bold">Análisis y plan: </span>{" "}
                            {props.generalFields?.analysisPlanJst}
                        </h5>
                    )}
                </div>
            </>
        );
    };

    return renderComponent();
}